import { createMuiTheme } from "@material-ui/core/styles"

// reference:
// https://material-ui.com/customization/default-theme/

export const MaterialFoxTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiGrid: {
            item: {
                padding: "5px"
            }
        }
    },
    palette: {
        primary: {
            light: "#FFB37D",
            main: "#FF832D",
            dark: "#E26005",
            contrastText: '#fff',
        },
        secondary: {
            light: "#6B90CE",
            main: "#4871B8",
            dark: "#2B5AA9",
            contrastText: "#fff",
        },
        text: {
            primary: "#27282c",
            secondary: "#949AA2"
        }
    }
})

export const FetchyColors = {
    primary: "#FF832D",
    secondary: "#4871B8",
    text: "#27282c"
}

export const FetchyStyles = {
    // "#1976d2",
    criticalButton: {
        backgroundColor: "#d32f2f",
        color: "#fff"
    },
    informationButton: {
        backgroundColor: "#4871B8",
        color: " #fff"
    },
    highlightText: {
        color: "#FF832D"
    },
    subtleText: {
        color: "#949AA2"
    }
}



