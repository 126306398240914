import { combineReducers } from "redux"

import airport from "./airport"
import credentials from "./credentials"

export default combineReducers({
    airport,
    credentials
})

