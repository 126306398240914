import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "recompose"
import firebase from "firebase/app"
import { withStyles } from "@material-ui/core/styles"

import { firebaseApp } from "../firebase"
import { StatusSnackbar, StatusSnackbarTypes } from "./common/components/StatusSnackbar"
import LoadingDialog from "./common/components/LoadingDialog"
import { DashboardAcccountsCollection } from "./common/firestore/core"
import { isEmptyString } from "./common/utils"
import { AdminAirportAPI } from "./common/requests/admin/airport"
import { setAirport, setCredentials, clearCredentials } from "../actions"
import FetchyFoxLogo from "./common/assets/logo.png"

import { Main } from "./views/Main"

const styles = theme => ({
    root: {
        display: "flex"
    },    
    welcomeImageContainer: {
        [theme.breakpoints.up('md')]: {
            height: "100vh"
        },
        [theme.breakpoints.down('sm')]: {
            height: "400px"
        },         
    },
    welcomeImage: {
        backgroundImage: `url(${process.env.REACT_APP_HOME_IMAGE})`,
        
        [theme.breakpoints.up('md')]: {
            backgroundSize: "cover",
            backgroundPosition: "50% 0",          
            width: "100%",
            height: "100%"
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: "cover",
            width: "100%",
            height: "400px"
        },        
    },
    fetchyFoxLogo: {
        marginTop: "20px",
        marginBottom: "20px",
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: "100%",
        height: "50px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }      
})


class MainContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            airports: [],
            airportSearch: "",
            username: "",
            secret: "",
            selectedAirportIATA: "",
            rememberMe: false,

            usernameErrorText: "",
            secretErrorText: "",
            airportErrorText: "",

            loading: false,
            redirectParams: null,
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            }
        }
    }

    _clearErrorText = () => {
        this.setState({usernameErrorText: "", secretErrorText: "", airportErrorText: ""})
    }

    /** BEGIN app lifecycle methods */

    componentWillMount = () => {

        // handles auto-login if the user previously selected auto login
        firebaseApp.auth().onAuthStateChanged(user => {
            if (user) {
                user.getIdToken().then((idToken) => {
                    this.props.setCredentials(user.email, idToken)
                    firebaseApp.firestore().collection(DashboardAcccountsCollection).doc(user.uid).get()
                    .then((document) => {
                        const { iata } = document.data()
                        this._getAirportDetails(iata, (airport) => {
                            this.props.setAirport(airport.id, airport.iata_code, airport.name, airport.primary_iso_4217,
                                airport.country, airport.db_tz_string)
                            this.setState({loading: false, redirectParams: {pathname: "/checkin"}})
                        })                                
                    })
                }).catch(error => {
                    this.props.clearCredentials(null, null)
                    this._getAirportList()                    
                })
            }
            else {
                this.props.clearCredentials(null, null)
                this._getAirportList()
            }
        })   
    }

    /** END lifecycle */

    /** BEGIN event listeners */

    _onSelectAirport = (selectedAirportIATA) => this.setState({selectedAirportIATA, airportErrorText: ""})
    _onChangeSearch = (airportSearch) => this.setState({airportSearch})
    _onChangeRememberMe = (rememberMe) => this.setState({rememberMe})
    _onChangeUsername = (username) => this.setState({username, usernameErrorText: ""})
    _onChangeSecret = (secret) => this.setState({secret, secretErrorText: ""})

    _onCloseSnack = () => this.setState({
        snackMessage:{
            message: "",
            level: StatusSnackbarTypes.INFO
        }
    })

    _onClickLogin = () => {
        this._clearErrorText()

        let usernameErrorText = ""
        let secretErrorText = ""
        let airportErrorText = ""
        
        if(isEmptyString(this.state.selectedAirportIATA)) {
            airportErrorText = "Select an airport."
        }

        if(isEmptyString(this.state.username)) {
            usernameErrorText = "Required"
        }

        if(isEmptyString(this.state.secret)) {
            secretErrorText = "Required"
        }

        if(!isEmptyString(usernameErrorText) || !isEmptyString(secretErrorText) || !isEmptyString(airportErrorText)) {
            this.setState({usernameErrorText, secretErrorText, airportErrorText})
        }
        else {
            let username = this.state.username
            if(username.indexOf("@fetchyfox.com") === -1){
                username = `${username}@fetchyfox.com`
            }

            this._firebaseLogin(username, this.state.secret)
        }
    }

    /** END event listeners */


    /** BEGIN network requrets */

    _getAirportList = (onSuccess=()=>{}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(process.env.REACT_APP_API_HOST, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestAllAirports(
            (success) => {
                const data  = success.data
                const airports = data.map((airport) => {
                    return {
                        value: airport.iata_code,
                        label: `${airport.iata_code} - ${airport.name}`
                    }
                })
                this.setState({loading: false, airports}, onSuccess)
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})
            }
        )
    }

    _getAirportDetails = (iataCode, onSuccess=(data)=>{}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(process.env.REACT_APP_API_HOST, process.env.REACT_APP_ADMIN_USER,
                                    process.env.REACT_APP_ADMIN_SECRET)
        api.requestAirportDetails(iataCode,
            (success) => {
                const airport = success.data
                this.setState({loading: false}, onSuccess(airport))
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})   
            })        
    }

    _firebaseLogin = (username, secret, onSuccess=()=>{}) => {
        this.setState({loading: true})
        const authMode = this.state.rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE 

        firebaseApp.auth().setPersistence(authMode)
            .then(() => {
                firebaseApp.auth().signInWithEmailAndPassword(username, secret)
                    .then(success => {
                        const user = firebaseApp.auth().currentUser
                        user.getIdToken().then((idToken) => {
                            firebaseApp.firestore().collection(DashboardAcccountsCollection).doc(user.uid).get()
                                .then((document) => {
                                    // TODO: need a field in the user data that determines if they have access to this dashboard
                                    const { airports, access, active, approved } = document.data()
                                    if(active && approved && access.pax && airports.includes(this.state.selectedAirportIATA)) {
                                        this.props.setCredentials(username, idToken)
                                        this._getAirportDetails(this.state.selectedAirportIATA, (airport) => {
                                            this.props.setAirport(airport.id, airport.iata_code, airport.name, airport.primary_iso_4217,
                                                airport.country, airport.db_tz_string)
                                            this.setState({redirectParams: {pathname: "/checkin"}})
                                        })
                                    }
                                    else {
                                        this.setState({
                                            loading: false,
                                            snackMessage: {
                                                message: "Authentication Error",
                                                level: StatusSnackbarTypes.ERROR
                                            }
                                        }, () => { setTimeout(firebaseApp.auth().signOut(), 5000)})                                        
                                    }
                                })                        
                        })
                    })
                    .catch(error => {
                        this.setState({
                            loading: false,
                            snackMessage: {
                                message: error.message,
                                level: StatusSnackbarTypes.ERROR
                            }
                        })
                    })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: error.message,
                        level: StatusSnackbarTypes.ERROR
                    }
                })
            })        
    }

    /** END network request */

    render = () => {

        if(this.state.redirectParams) {
            return <Redirect push to={this.state.redirectParams}/>
        }

        return (
            <div style={{height: "99vh", width: "100vw"}}>
                <main>
                    <Main
                        state={this.state}
                        classes={this.props.classes}
                        onSelectAirport={this._onSelectAirport}
                        onChangeSearch={this._onChangeSearch}
                        onChangeUsername={this._onChangeUsername}
                        onChangeSecret={this._onChangeSecret}
                        onChangeRememberMe={this._onChangeRememberMe}
                        onClickLogin={this._onClickLogin}/>
                </main>
                <LoadingDialog show={this.state.loading}/>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._onCloseStatusSnackbar}/>                    
            </div>
        )
    }
}

function mapStateToProps(state) {}

const mapActionCreatorsToProps = {
    setCredentials,
    clearCredentials,
    setAirport
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(MainContainer)
