import React from "react"
import PropTypes from "prop-types"
import MUIDataTable from "mui-datatables"

import DateRangeInput from "../common/components/DateRangeInput"
import { LineGraph } from "../views/LineGraph"
import { BarGraph } from "../views/BarGraph"

import {
    Grid,
    Typography
} from "@material-ui/core"

import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

export const Analytics = (props) => {
    const { classes, state } = props

    const tableOptions = {
        selectableRows: "none",
        selectableRowsHeader: false,
        responsive: "scrollMaxHeight",
        viewColumns: false,        
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2">Check-In Analytics</Typography>
                <div style={{height: 20, width: 800}}/>
                <Typography variant="h4">Past Week</Typography>
            </Grid>
            <Grid item xs={6}>
                <LineGraph
                    title="Passenger Check-In"
                    xLabel="Date"
                    dataName="# Passengers"
                    graphData={state.lineGraphData}/>
            </Grid>
            <Grid item xs={6}>
                <BarGraph
                    title="Assistance Type"
                    xLabel="Categories"
                    dataName="# Reported"
                    graphData={state.barGraphData}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Historical Data</Typography>
                <div style={{height: 10}}/>
                <DateRangeInput 
                    onClickUpdate={props.onClickDateRangeUpdate}
                    initStartDate={state.historicalRange.start}
                    initEndDate={state.historicalRange.end}
                    />
                <div style={{height: 20}}/>                    
                <MUIDataTable
                    title="Daily Passengers"
                    columns={state.tableColumns}
                    data={state.tableRows}
                    options={tableOptions}/>
            </Grid>
        </Grid>
    )
}

Analytics.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onClickDateRangeUpdate: PropTypes.func.isRequired
}

Analytics.defaultProps = {
    onClickDateRangeUpdate: (dateRange) => {}
}