import { SET_AIRPORT, CLEAR_AIRPORT, SET_CREDENTIALS, CLEAR_CREDENTIALS } from "../constants"


export function setAirport(airportId, iataCode, name, iso4217, country, timezone) {
    const action = {
        type: SET_AIRPORT,
        airportId,
        iataCode,
        name,
        iso4217,
        country,
        timezone,
    }
    return action
}

export function clearAirport() {
    const action = {
        type: CLEAR_AIRPORT
    }
    return action
}

export function setCredentials(username, secret) {
    const action = {
        type: SET_CREDENTIALS,
        username,
        secret
    }

    return action
}

export function clearCredentials() {
    const action = {
        type: CLEAR_CREDENTIALS,
        username: null,
        secret: null
    }
    return action
}