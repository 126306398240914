
export function formatFetchyAPISuccess(response) {
    let result = {
        responseCode: response.status,
        message: response.message,
        data: response.data,
        headers: response.headers,
        is20x: true
    }

    return result
}

export function formatFetchyAPIFailure(response) {
    return handleFetchyAPIError(response)
}

export function handleFetchyAPIError(error) {
    // return an object containing HTTP status code and error message
    let result = {
        responseCode: null,
        message: null,
        data: null,
        headers: error.headers,
        is20x: false
    }

    if (error.response) {
        // request successfully sent and received by destination server but response was
        // either 40x or 50x
        const errType = parseInt(error.response.status / 100, 10)
        result.message = (errType === 4) ? error.response.data.error : error.response.data.exception
        result.responseCode = error.response.status
        result.data = error.response.data
    }
    else if (error.request) {
        // request sent successfully but not response from server
        console.log("unable to reach server: ", error.request)
        result.message = "Unable to reach server."
    }
    else {
        // unexpected error.
        console.log("Unexpected Error: ", error.message)
        result.message = "Unexpected network error. See logs."
    }

    return result
}
