import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core"

import { FlightLookup } from "../views/FlightLookup"
import { StatusSnackbar, StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { AdminAirportAPI } from "../common/requests/admin/airport"
import { isEmptyString } from "../common/utils"

const moment = require("moment-timezone")

class FlightLookupDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flightCode: "",
            flightResults: [],
            flightData: null,
            loading: false,
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            },

            flightCodeError: "",
        }
    }

    _clearErrors = () => {
        this.setState({
            flightCodeError: ""
        })
    }

    /** event listeners */

    _onCloseSnackbar = () => {
        this.setState({
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            }
        })
    }

    _onChangeFlightCode = (flightCode) => this.setState({flightCode: flightCode.toUpperCase()})

    _onClickSearch = () => {
        this._clearErrors()
        this.setState({flightResults: []})
        if(isEmptyString(this.state.flightCode)) {
            this.setState({flightCodeError: "Required"})
        }
        else {
            this._getDepartureDetails()
        }
        
    }
    

    _onSelectFlight = (selectedFlightIdx) => {
        const flightData = this.state.flightResults[selectedFlightIdx]
        this.setState({flightResults: [], flightData})
    }

    /** END event listeners */


    /** network requests */

    _getDepartureDetails = () => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(process.env.REACT_APP_API_HOST, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestDepartureInfo(this.props.airport.airportId, this.state.flightCode,
            (success) => {
                const data = success.data
                this._processFlightResults(data)
                // if there's only one flight, then automatically set it as the selected one.
                // otherwise set it to results and let the UI layer handle it
                if(data.length === 1) {
                    this.setState({loading: false, flightData: data[0], flightResults: []})
                }
                else {
                    this.setState({loading: false, flightData: null, flightResults: data})
                }
                
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})   
            })
    }

    /** END network requests */

    _processFlightResults = (flightData) => {
        // transform raw flight data into a format for display. getting the string times to be
        // moment objects in local time. in place update

        const localTZ = this.props.airport.timezone
        flightData.map(datum => {
            let estimatedDepartureUTC = moment(datum.estimated_departure_utc).tz("UTC")
            let scheduledDepartureUTC = moment(datum.scheduled_departure_utc).tz("UTC")
            let estimatedDepatureLocal = moment(estimatedDepartureUTC).tz(localTZ)
            let scheduledDepartureLocal = moment(scheduledDepartureUTC).tz(localTZ)
            datum.estimated_departure_utc = estimatedDepartureUTC
            datum.scheduled_departure_utc = scheduledDepartureUTC
            datum.estimatedDepartureLocal = estimatedDepatureLocal
            datum.scheduledDepartureLocal = scheduledDepartureLocal

            return 1
        })
    }


    render = () => {
        return (
            <Dialog open 
                maxWidth="sm"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>Flight Lookup</DialogTitle>
                <DialogContent>
                    <FlightLookup
                        state={this.state}
                        onClickSearch={this._onClickSearch}
                        onSelectFlight={this._onSelectFlight}
                        onChangeFlightCode={this._onChangeFlightCode}/>
                    <StatusSnackbar
                        open={!isEmptyString(this.state.snackMessage.message)}
                        variant={this.state.snackMessage.level}
                        message={this.state.snackMessage.message}
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        onClose={this._onCloseSnackbar}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

FlightLookup.propTypes = {
    onClose: PropTypes.func.isRequired
}

FlightLookup.defaultProps = {}


function mapStateToProps(state) {
    const { airport } = state
    return { airport }
}

const mapActionCreatorsToProps = {}

export default connect(mapStateToProps, mapActionCreatorsToProps)(FlightLookupDialog)

