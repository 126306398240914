import React from "react"
import PropTypes from "prop-types"

import {
    Typography,
    Grid,
    TextField,
    Button,
    Checkbox,
    FormControlLabel
} from "@material-ui/core"

import AutoCompleteField from "../common/components/AutoCompleteField"
import MaskedPasswordField from "../common/components/MaskedPasswordField"
import { isEmptyString } from "../common/utils"

export const Main = (props) => {
    const { classes, state } = props

    return (
        <Grid container >
            <Grid item xs={12} md={7} style={{padding: 0, margin: 0}}>
                <Grid container className={classes.welcomeImageContainer}>
                    <Grid item xs={12} style={{padding: 0}}>
                        <div className={classes.welcomeImage}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} style={{padding: 0}}>
                <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
                    <Grid item xs={11}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.fetchyFoxLogo}/>
                                <Typography variant="h2">PAX Management</Typography>
                                <div style={{height: 40}}/>
                            </Grid>
                        </Grid>
                        <AutoCompleteField
                            label="Your airport"
                            searchPhrase={state.airportSearch}
                            suggestions={state.airports}
                            onSelectCallback={(iataCode) => props.onSelectAirport(iataCode)}
                            onTypeSearchPhrase={(phrase) => props.onChangeSearch(phrase)}
                            errorText={state.airportErrorText}/>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Username"
                                    color="primary"
                                    value={state.username}
                                    onChange={(event) => props.onChangeUsername(event.target.value)}
                                    error={!isEmptyString(state.usernameErrorText)}
                                    helperText={state.usernameErrorText}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12}>
                                <MaskedPasswordField
                                    variant="outlined"
                                    label="Passphrase"
                                    value={state.secret}
                                    onTypePassword={(value) => props.onChangeSecret(value)}
                                    errorText={state.secretErrorText}
                                    fullWidth/>
                            </Grid>                            
                        </Grid>
                        <Grid container justify="flex-end">
                            <Grid item xs={12} sm={8}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.rememberMe}
                                            onChange={(event) => props.onChangeRememberMe(event.target.checked)}
                                            value="rememberMe"
                                            color="primary"/>
                                    }
                                    label="Remember this device"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={props.onClickLogin}
                                    fullWidth>Login</Button>
                            </Grid>
                        </Grid>  
                        <div style={{height: 20}}/>
                        <Grid container>
                            <Grid item>
                                <Typography variant="caption">{process.env.REACT_APP_VERSION}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">{ process.env.REACT_APP_DEVELOPMENT === "1" ? "DEVELOPMENT" : ""}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography variant="caption">FetchyFox Inc {new Date().getFullYear()}</Typography>
                            </Grid>
                        </Grid>                                              
                    </Grid>
                </Grid>
            </Grid>            
        </Grid>
    )
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onSelectAirport: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onChangeUsername: PropTypes.func.isRequired,
    onChangeSecret: PropTypes.func.isRequired,
    onChangeRememberMe: PropTypes.func.isRequired,
    onClickLogin: PropTypes.func.isRequired
}

Main.defaultProps = {
    onSelectAirport: (iata) => {},
    onChangeSearch: (value) => {},
    onChangeUsername: (value) => {},
    onChangeSecret: (value) => {},
    onChangeRememberMe: (checked) => {},
    onClickLogin: () => {}
}