import React from "react"
import ListIcon from "@material-ui/icons/List"
import TimelineIcon from "@material-ui/icons/Timeline"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"

export const SET_AIRPORT = "SET_AIRPORT"
export const CLEAR_AIRPORT = "CLEAR_AIRPORT"

export const SET_CREDENTIALS = "SET_CREDENTIALS"
export const CLEAR_CREDENTIALS = "CLEAR_CREDENTIALS"

// disability categories as defined by IATA
export const iataDisabilityCategories = [
    "BLND",
    "DEAF",
    "WCHS",
    "WCHR",
    "WCHC",
    "DPNA",
    "Other"
]

export const drawerMenuItems = [
    {
        label: "Passenger Check-In",
        icon: <ListIcon />,
        redirectParams: {pathname: "/checkin"},
        listed: true,
    },
    {
        label: "Analytics",
        icon: <TimelineIcon/>,
        redirectParams: {pathname: "/analytics"},
        listed: true,
    },
    // {
    //     label: "Browse Menus",
    //     icon: <ShoppingCartIcon />,
    //     redirectParams: {pathname: "/menu"},
    //     listed: true,
    // }
]