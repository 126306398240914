import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"

import CanvasJSReact from "../common/canvasjs.react"
import { FetchyColors } from "../common/fetchyfoxtheme"
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


export const BarGraph = (props) => {
    const { state } = props

    const graphOptions = {
        animationEnabled: true,
        exportEnabled: true,
        zoomEnabled: true,
        theme: "light2",
        title: { text: props.title },
        axisX: { title: props.xLabel },
        axisY: [
            {
                lineColor: FetchyColors.primary,
                titleFontColor: FetchyColors.text,
                labelFontColor: FetchyColors.text
            }
        ],
        data: [
            {
                type: "column",
                name: props.dataName,
                showInLegend: true,
                dataPoints: props.graphData,
                color: FetchyColors.primary,
            },   
        ]
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                <CanvasJSChart options={graphOptions} style={{width: "100%"}}/>
            </Grid>
        </Grid>
    )
}

BarGraph.propTypes = {
    state: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    xLabel: PropTypes.string.isRequired,
    dataName: PropTypes.string.isRequired,
    graphData: PropTypes.array,
}

BarGraph.defaultProps = {
    graphData: [
        {label: 1, y: Math.random() * 2000},
        {label: 2, y: Math.random() * 2000},
        {label: 3, y: Math.random() * 2000},
        {label: 4, y: Math.random() * 2000},
        {label: 5, y: Math.random() * 2000},
        {label: 6, y: Math.random() * 2000},
        {label: 7, y: Math.random() * 2000},
    ],
}

export default BarGraph