import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProvider } from "react-intl"
import { Route, Switch, Redirect } from "react-router"
import { createStore } from "redux"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core/styles"

import { MaterialFoxTheme } from "./components/common/fetchyfoxtheme"

import CheckinContainer from "./components/Checkin"
import AnalyticsContainer from "./components/Analytics"
import MainContainer from "./components/Main"
import { setCredentials, clearCredentials } from "./actions"
import reducer from "./reducers"
import { firebaseApp } from "./firebase"

const store = createStore(reducer)

firebaseApp.auth().onAuthStateChanged(user => {
    if (user) {
        user.getIdToken().then((idToken) => {
            store.dispatch(setCredentials(user.email, idToken))
        })
    }
    else {
        store.dispatch(clearCredentials())
    }
})

function isAuthorized() {
    const state = store.getState()
    return state.credentials.username !== undefined && state.credentials.username !== null
}

const App = () => {
    return (
        <IntlProvider locale={navigator.language}>
            <Provider store={ store }>
                <MuiThemeProvider theme={MaterialFoxTheme}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path ="/" render={(props) => ( <MainContainer {...props}/> )}/>
                        </Switch>
                        <Switch>
                            <Route exact path ="/checkin" render={(props) => (
                                isAuthorized() ? <CheckinContainer {...props}/> : <Redirect to="/"/>
                            )}/>
                        </Switch>
                        <Switch>
                            <Route exact path ="/analytics" render={(props) => (
                                isAuthorized() ? <AnalyticsContainer {...props}/> : <Redirect to="/"/>
                            )}/>
                        </Switch>
                    </BrowserRouter>
                </MuiThemeProvider>
            </Provider>
        </IntlProvider>
    )
}

ReactDOM.render(
    <App /> , document.getElementById("root")
)
