import React from "react"
import PropTypes from "prop-types"
import MUIDataTable from "mui-datatables"

import {
    Grid,
    Typography,
    Button
} from "@material-ui/core"

import AddIcon from "@material-ui/icons/AddBox"


export const Checkin = (props) => {
    const { classes, state } = props

    const tableOptions = {
        selectableRows: "none",
        selectableRowsHeader: false,
        responsive: "scrollMaxHeight",
        print: false,
        viewColumns: false,
    }

    return (
        <div style={{display: "flex"}}>
            <main>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2">Check-In Dashboard</Typography>
                        <div style={{height: 20}}/>
                        <Typography variant="h3">{state.currentTime}</Typography>
                        <Typography variant="h6" color="primary">{state.airport.timezone}</Typography>
                        <div style={{height: 20}}/>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            onClick={() => props.onClickAddPassenger()} 
                            color="primary"
                            startIcon={<AddIcon/>}>Check-In</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => props.onClickFlightLookup()}>Flight Lookup</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title="Passenger List"                        
                            columns={state.tableColumns}
                            data={state.tableData}
                            options={tableOptions}/>
                    </Grid>
                </Grid>
            </main>
        </div>
    )
}

Checkin.propTypes = {
    state: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onClickAddPassenger: PropTypes.func.isRequired,
    onClickFlightLookup: PropTypes.func.isRequired
}