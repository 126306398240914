import { SET_CREDENTIALS, CLEAR_CREDENTIALS } from "../constants"

export default (state = [], action) => {
    switch(action.type) {
        case SET_CREDENTIALS:
            const { username, secret } = action
            return { username, secret }
        case CLEAR_CREDENTIALS:
            return {
                username: null,
                secret: null
            }
        default:
            return state
    }
}