import React from "react"
import PropTypes from "prop-types"

import {
    Grid,
    Button,
    TextField,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText

} from "@material-ui/core"

import FlightDepartIcon from "@material-ui/icons/FlightTakeoff"
import FlightArriveIcon from "@material-ui/icons/FlightLand"
import RightArrowIcon from "@material-ui/icons/ArrowRightAlt"
import AirplaneIcon from "@material-ui/icons/AirplanemodeActive"

import LoadingDialog from "../common/components/LoadingDialog"
import { isEmptyString } from "../common/utils"

function _renderSearch(props) {
    const { classes, state } = props

    if(props.hideSearch)
        return <div/>
    else
        return (
            <Grid container>
                <Grid item xs={9}>
                    <TextField 
                        fullWidth
                        value={state.flightCode}
                        helperText={state.flightCodeError}
                        error={!isEmptyString(state.flightCodeError)}
                        onChange={(event) => {props.onChangeFlightCode(event.target.value)}}
                        size="small" 
                        variant="outlined" 
                        label="Flight Number" 
                        placeholder="FFX1339"/>
                </Grid>
                <Grid item xs={3}>
                    <Button 
                        fullWidth
                        onClick={props.onClickSearch}
                        variant="contained" 
                        color="primary">Search</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        )
}

function _renderFlightInfo(props) {
    const { classes, state } = props
    const flightData = state.flightData

    if(flightData) {
        // show flight data of selected flight
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h4" color="primary">{flightData.flight_number}</Typography>
                            <Typography variant="h6">{`Estimated Departure: ${flightData.estimatedDepartureLocal.format("lll")} (local)`}</Typography>
                            <Typography variant="subtitle1">{`Scheduled Departure: ${flightData.scheduledDepartureLocal.format("lll")} (local)`}</Typography>
                            <Typography variant="subtitle1">{`${flightData.origin_terminal}: Gate ${flightData.origin_gate}`}</Typography>
                            <div style={{height: 20}}/>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <FlightDepartIcon fontSize="large"/>
                            <Typography variant="h3">{flightData.origin_airport}</Typography>
                            <Typography variant="subtitle1">{flightData.origin_city}</Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <RightArrowIcon fontSize="large"a/>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <FlightArriveIcon fontSize="large"/>
                            <Typography variant="h3">{flightData.direct_airport}</Typography>
                            <Typography variant="subtitle1">{flightData.direct_city}</Typography>
                        </Grid>                                                        
                    </Grid>
                </Grid>
            </Grid>             
        )
    }
    else if(state.flightResults && state.flightResults.length > 1) {
        // show options to select a flight
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">Select your flight:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <List aria-label="Flight Results">
                        {
                            state.flightResults.map((flight, idx) => {
                                return (
                                    <ListItem button key={idx} onClick={()=>{props.onSelectFlight(idx)}}>
                                        <ListItemText
                                            primary={
                                                <Typography variant="h6">{`${flight.origin_airport} -> ${flight.direct_airport}`}</Typography>
                                            }
                                            secondary={
                                                <Typography variant="body1">{`Scheduled Departure: ${flight.scheduledDepartureLocal.format("lll")}`}</Typography>
                                            }/>
                                    </ListItem>
                                )
                            })
                        }
                    </List>

                </Grid>
            </Grid>
        )
    }
    else {
        // no flight has been searched or selected
        return (
            <Grid container>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <div style={{height: 20}}/>
                    <AirplaneIcon fontSize="large"/>
                    <Typography variant="h6">No Flight Selected</Typography>
                    <div style={{height: 20}}/>
                </Grid>
            </Grid>
        )
    }

}

export const FlightLookup = (props) => {
    const { classes, state } = props
    return (
        <div style={{display: "flex"}}>
            <main style={{width: "100%"}}>
                { _renderSearch(props) }
                { _renderFlightInfo(props) }
                <LoadingDialog show={state.loading}/>
            </main>
        </div>
    )
}

FlightLookup.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onChangeFlightCode: PropTypes.func.isRequired,
    onClickSearch: PropTypes.func.isRequired,
    onSelectFlight: PropTypes.func.isRequired,
}

FlightLookup.defaultProps = {
    onChangeFlightCode: (value) => {},
    onClickSearch: () => {},
    onSelectFlight: (idx) => {},
}