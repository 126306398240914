import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { compose } from "recompose"

import { withStyles } from "@material-ui/core/styles"

import {
    Grid,
    Button,
    Checkbox
} from "@material-ui/core"

import { setAirport, clearCredentials } from "../actions"
import FetchyFoxLogo from "../assets/logo.png"
import { firebaseApp } from "../firebase"
import { drawerMenuItems } from "../constants"
import NavDrawer from "./common/components/NavDrawer"
import LoadingDialog from "./common/components/LoadingDialog"
import { StatusSnackbar, StatusSnackbarTypes } from "./common/components/StatusSnackbar"
import { FetchyIntercom } from "./common/components/FetchyIntercom"
import { isEmptyString } from "./common/utils"
import { PaxCheckinAPI} from "./common/firestore/paxcheckins"
import { FetchyStyles } from "./common/fetchyfoxtheme"
import FlightLookupDialog from "./modals/FlightLookup"
import PassengerDetailsDialog from "./modals/PassengerDetails"
import ConfirmPickupDialog from "./modals/ConfirmPickup"
import { Checkin } from "./views/Checkin"


const moment = require("moment-timezone")


const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }, 
})

class CheckinContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dialogComponent: null,
            tableColumns: [
                {name: "name", label: "Name", options: {filter: false, sort: true}},
                {name: "checkin", label: "Check-In Time", options: {filter: false, sort: true}},
                {name: "category", label: "Category", options: {filter: true, sort: false}},
                {name: "flightNumber", label: "Flight Number", options: {filter: true, sort: true}},
                {name: "pickupTime", label: "Scheduled Pickup Time", options: {filter: false, sort: true}},
                {name: "closeTime", label: "Gate Closing Time", options: {filter: false, sort: true}},
                {name: "status", label: "Status", options: {filter: false, sort: false}},
                {name: "purchased", label: "Purchased Something", options: {filter: true, sort: false, viewColumns: false,
                    download: true,
                    print: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let firebaseData = this.state.passengerData[tableMeta.rowIndex]
                        return (
                            <Grid container>
                                <Grid item>
                                    <Checkbox 
                                        onChange={(event) => this._onChangePurchased(firebaseData.id, event.target.checked)} 
                                        checked={firebaseData.purchased} color="primary"/>
                                </Grid>
                            </Grid>
                        )
                    }
                }},
                {name: "actions", label: " ", options: {filter: false, sort: false, viewColumns: false,
                    download: false,
                    print: false,
                    customBodyRender: (value, tableMeta, updateValue) => {     
                        let rowData = this.state.tableData[tableMeta.rowIndex]
                        let firebaseData = this.state.passengerData[tableMeta.rowIndex]
                        return (
                            <Grid container>
                                <Grid item>
                                    <Button 
                                        disabled={rowData.actualPickupTime !== null}
                                        onClick={ () => this._onClickViewPassenger(rowData.id)}>
                                            Details</Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        disabled={rowData.actualPickupTime !== null}
                                        onClick={ () => this._onClickPickup(rowData.id, rowData.flightNumber, rowData.notes,
                                            firebaseData.flightData)} 
                                        color="primary">Confirm Pickup
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    }
                
                }}
            ],
            tableData: [],
            passengerData: [], // raw data from firestore
            
            firebasePaxListener: null,
            clockTimeout: null,
            currentTime: null,
            airport: props.airport,

            loading: false,
            location: props.location,
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            },
            redirectParams: null
        }
    }

    componentWillMount = () => {
        const today = moment().tz("UTC")
        this._firebaseGetPassengers(today, today)
        this._firebaseRegisterListeners(today, today)

        this.setState({
            clockInterval: setInterval(() => {
                this.setState({currentTime: moment().tz(this.props.airport.timezone).format("h:mm:ss A")})
            }, 1000)
        })

    }

    componentDidMount = () => {

    }

    componentWillUnmount = () => {
        // unsubscriber listener
        if(this.state.firebasePaxListener) {
            this.state.firebasePaxListener()
        }
        if(this.state.clockInterval)
            clearInterval(this.state.clockInterval)
    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    /** event listeners */

    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearCredentials()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: {
                message: error.message,
                level: StatusSnackbarTypes.ERROR
            }})
        })
    }

    _onCloseStatusSnackbar = () => {
        this.setState({
            snackMessage: {
                level: StatusSnackbarTypes.INFO,
                message: "",
            }
        })
    }

    _onChangePurchased = (documentId, purchased) => {
        const payload = { purchased }
        this._firebaseUpdatePassenger(documentId, payload)
    }

    _onClickPickup = (documentId, flightCode, notes, flightData) => {
        this.setState({
            dialogComponent: <ConfirmPickupDialog 
                                flightCode={flightCode}
                                flightData={flightData}
                                notes={notes}
                                onClickPickup={(data) => {
                                    this._onClickPickupPassenger(documentId, data)
                                }}
                                onClose={this._onCloseDialog}/>
        })
    }

    _onClickFlightLookup = () => {
        this.setState({
            dialogComponent: <FlightLookupDialog onClose={this._onCloseDialog}/>
        })
    }

    _onClickViewPassenger = (documentID) => {
        const data = this.state.passengerData.filter(datum => datum.id === documentID)
        let passengerData
        if(data.length === 1) {
            passengerData = data[0]
            this.setState({
                dialogComponent: <PassengerDetailsDialog
                                    variant="update"
                                    passengerData={passengerData}
                                    onClose={this._onCloseDialog}
                                    onClickCheckin={(passengerData) => this._onClickUpdatePassenger(documentID, passengerData)}/>
            })
        }
        else {
            this.setState({
                snackMessage: {
                    message: "Couldn't load passenger data. Refresh the table and try again.",
                    level: StatusSnackbarTypes.WARNING
                }
            })
        }
    }

    _onClickAddPassenger = () => {
        this.setState({
            dialogComponent: <PassengerDetailsDialog 
                                variant="create"
                                onClose={this._onCloseDialog}
                                onClickCheckin={this._onClickCheckinPassenger}/>
        })
    }

    _onClickUpdatePassenger = (documentId, passengerData) => {
        // convert from moment objects to firestore timestamps
        passengerData.pickupTime = passengerData.pickupTime.tz("UTC").toDate()
        passengerData.closeTime = passengerData.closeTime.tz("UTC").toDate()
        passengerData.checkin = passengerData.checkin.tz("UTC").toDate()
        passengerData.actualPickupTime = passengerData.actualPickupTime ? passengerData.actualPickupTime.tz("UTC").toDate() : null

        passengerData.flightData.estimated_departure_utc = passengerData.flightData.estimated_departure_utc.tz("UTC").toDate()
        passengerData.flightData.scheduled_departure_utc = passengerData.flightData.scheduled_departure_utc.tz("UTC").toDate()
        delete passengerData.flightData.estimatedDepartureLocal
        delete passengerData.flightData.scheduledDepartureLocal


        this.setState({dialogComponent: null}, this._firebaseUpdatePassenger(documentId, passengerData))
    }

    _onCloseDialog = () => {
        this.setState({dialogComponent: null})
    }

    _onClickCheckinPassenger = (passengerData) => {

        // convert all times to UTC for firestore. all other data stays the same
        passengerData.pickupTime = passengerData.pickupTime.tz("UTC").toDate()
        passengerData.closeTime = passengerData.closeTime.tz("UTC").toDate()
        passengerData.checkin = passengerData.checkin.tz("UTC").toDate()

        passengerData.flightData.estimated_departure_utc = passengerData.flightData.estimated_departure_utc.tz("UTC").toDate()
        passengerData.flightData.scheduled_departure_utc = passengerData.flightData.scheduled_departure_utc.tz("UTC").toDate()
        delete passengerData.flightData.estimatedDepartureLocal
        delete passengerData.flightData.scheduledDepartureLocal

        this.setState({dialogComponent: null}, this._firebaseAddPassenger(passengerData))
    }

    _onClickPickupPassenger = (documentId, data) => {
        data.actualPickupTime = data.actualPickupTime.tz("UTC").toDate()
        this.setState({dialogComponent: null}, this._firebaseUpdatePassenger(documentId, data))
    }

    /** end event listeners */

    /** network requests */

    _firebaseGetPassengers = (startDate, endDate, onSuccess=()=>{}) => {
        this.setState({loading: true})
        const api = new PaxCheckinAPI(firebaseApp)
        api.getPassengers(startDate, endDate,
            (passengerData) => {
                this.setState({loading: false}, this._processPassengerData(passengerData))
            },
            (errorMessage) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: errorMessage,
                        level: StatusSnackbarTypes.ERROR
                    }
                })
            })
    }

    _firebaseUpdatePassenger = (documentId, data) => {
        this.setState({loading: true})
        const api = new PaxCheckinAPI(firebaseApp)
        api.updatePassenger(documentId, data, 
            () => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: "Passenger information updated.",
                        level: StatusSnackbarTypes.SUCCESS
                    }
                })     
            },
            (errorMessage) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: "Couldn't update the passenger. Please try again.",
                        level: StatusSnackbarTypes.ERROR
                    }
                })
            })
    }

    _firebaseAddPassenger = (passengerData) => {
        this.setState({loading: true})
        const api = new PaxCheckinAPI(firebaseApp)
        api.addPassenger(passengerData.name, passengerData.categories, passengerData.notes, passengerData.flightCode, 
            passengerData.flightData, passengerData.checkin, passengerData.pickupTime, passengerData.closeTime, passengerData,
            () => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: `${passengerData.name} successfully checked in.`,
                        level: StatusSnackbarTypes.SUCCESS
                    }
                })
            },
            (errorMessage) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: `There was a problem checking in ${passengerData.name}. Please try again.`,
                        level: StatusSnackbarTypes.ERROR
                    }
                })
            })
    }

    _firebaseRegisterListeners = (startDate, endDate, onSuccess=()=>{}) => {
        // https://firebase.google.com/docs/firestore/query-data/listen
        // receives a list of all documents when any of them are updated
        const api = new PaxCheckinAPI(firebaseApp)
        api.registerListener(startDate, endDate, this._processPassengerData, 
            (listener) => {
                this.setState({firebasePaxListener: listener}, onSuccess)
            })
    }

    /** end network requests */

    _processPassengerData = (data) => {
        // format raw json data coming from firebase into the format needed for
        // the UI. updates are in place.
        const localTZ = this.props.airport.timezone

        // format the data from firebase, this involves converting firebase timestamps into Moment objects
        const passengerData = data.map(datum => {
            return {
                ...datum,
                actualPickupTime: datum.actualPickupTime ? moment(datum.actualPickupTime.seconds * 1000).tz("UTC").tz(localTZ) : null,
                checkin: moment(datum.checkin.seconds * 1000).tz("UTC").tz(localTZ),
                closeTime: moment(datum.closeTime.seconds * 1000).tz("UTC").tz(localTZ),
                pickupTime: moment(datum.pickupTime.seconds * 1000).tz("UTC").tz(localTZ),
            }
        })

        // format the firebase data for the table component, mostly formating the timestamps
        const tableData = passengerData.map((datum, idx) => {
            return {
                id: datum.id,
                name: datum.name,
                checkin: datum.checkin.format("lll"),
                category: datum.categories.join(", "),
                flightNumber: datum.flightCode,
                pickupTime: datum.pickupTime.format("lll"),
                closeTime: datum.closeTime.format("lll"),
                status: datum.status,
                purchased: datum.purchased ? "Yes" : "No",
                notes: datum.notes,
                actualPickupTime: datum.actualPickupTime ? datum.actualPickupTime.format("lll") : null,
            }
        })



        this.setState({tableData, passengerData})
    }

    _renderDialogs = () => {
        if(this.state.dialogComponent)
            return this.state.dialogComponent
        else
            return <div/>
    }

    render = () => {

        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect push to={this.state.redirectParams}/>

        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={drawerMenuItems}
                    currentPath={this.state.location.pathname}
                    onClickDrawerItemCallback={(redirectParams)=> this.setState({redirectParams})}
                    onClickLogoutCallback={this._onClickDrawerLogout}/>
                <main>
                    <div className={this.props.classes.spacerTop}/>
                    <Checkin 
                        classes={this.props.classes} 
                        state={this.state}
                        onClickAddPassenger={this._onClickAddPassenger}
                        onClickFlightLookup={this._onClickFlightLookup}/>
                    { this._renderDialogs() }
                </main>
                <LoadingDialog show={this.state.loading}/>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._onCloseStatusSnackbar}/>
                <FetchyIntercom
                    userId="pax-dashboard"
                    name="PAX Dashboard"
                    airport={this.props.airport.iataCode}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { airport } = state
    return { airport }
}

const mapActionCreatorsToProps = {
    setAirport,
    clearCredentials
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(CheckinContainer)

