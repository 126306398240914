import * as d3 from "d3"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { compose } from "recompose"
import { withStyles } from "@material-ui/core/styles"

import FetchyFoxLogo from "../assets/logo.png"
import { firebaseApp } from "../firebase"
import { clearCredentials } from "../actions"
import { iataDisabilityCategories, drawerMenuItems } from "../constants"
import NavDrawer from "./common/components/NavDrawer"
import LoadingDialog from "./common/components/LoadingDialog"
import { PaxCheckinAPI } from "./common/firestore/paxcheckins"
import { StatusSnackbar, StatusSnackbarTypes } from "./common/components/StatusSnackbar"
import { isEmptyString } from "./common/utils"
import { FetchyStyles } from "./common/fetchyfoxtheme"
import { Analytics } from "./views/Analytics"


const styles = theme => ({
    ...FetchyStyles,
    fetchyFoxLogo: {
        backgroundImage: `url(${FetchyFoxLogo})`,
        width: 150,
        height: theme.mixins.toolbar.minHeight / 2,
        paddingTop: theme.mixins.toolbar.minHeight / 4,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    },
    toolbar: theme.mixins.toolbar,
    spacerTop: {
        height: theme.mixins.toolbar.minHeight + 20
    }, 
})

const moment = require("moment-timezone")

class AnalyticsContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tableColumns: [
                {name: "date", label: "Date", options: {filter: false, sort: true}},
                {name: "passengers", label: "# Passengers", options: {filter: false, sort: true}},
                {name: "deliveries", label: "# Deliveries", options: {filter: false, sort: true}},
                {name: "dwell", label: "Avg. Dwell Time", options: {filter: false, sort: true}},
                {name: "ontime", label: "On-Time Pickup %", options: {filter: false, sort: true}},
            ],
            tableRows: [],
            

            historicalRange: {
                start: moment().subtract(14, "days"),
                end: moment()
            },


            barGraphData: iataDisabilityCategories.map(category => {
                return {label: category, y: 0}
            }),
            lineGraphData: [],

            location: props.location,
            loading: false,
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            },
            redirectParams: null 
        }
    }

    componentWillMount = () => {
        // historical table data
        this._firebaseGetPassengerData(this.state.historicalRange.start, this.state.historicalRange.end,
            this._processHistoricalData)

        // weekly graph data
        this._firebaseGetPassengerData(moment().subtract(7, "days"), moment(),
            this._processWeeklyGraphData)
    }

    /** event listeners */
    _onClickDrawerLogout = () => {
        this.setState({loading: true})
        firebaseApp.auth().signOut()
        .then(() => {
            this.props.clearCredentials()
            this.setState({loading: false, redirectParams: {pathname: "/"}})
        })
        .catch(error => {
            this.setState({loading: false, snackMessage: {
                message: error.message,
                level: StatusSnackbarTypes.ERROR
            }})
        })
    }

    _onClickDateRangeUpdate = (start, end) => {
        this.setState({historicalRange: { start, end } }, () => {
            this._firebaseGetPassengerData(start, end, this._processHistoricalData)
        })
    }
    /** END event listeners */


    /** network requests */

    _firebaseGetPassengerData = (startDate, endDate, onSuccess=()=>{}) => {
        this.setState({loading: true})
        const api = new PaxCheckinAPI(firebaseApp)
        api.getPassengers(startDate, endDate,
            (passengerData) => {
                this.setState({loading: false}, onSuccess(passengerData))
            },
            (errorMesssage) => {
                this.setState({
                    loading: false,
                    snackMessage: {
                        message: errorMesssage,
                        level: StatusSnackbarTypes.ERROR
                    }
                })
            })
    }

    /** END netwok requets */

    _processWeeklyGraphData = (data) => {

        // TODO: use airport timezone
        data.map(datum => {
            datum.checkin = moment(datum.checkin.seconds * 1000).tz("UTC")
            return 1
        })
        
        // format data for disabilities bar graph
        let categories = data.map(datum => {
            return datum.categories
        })

        // we add one of each label so that they all appear in the next "group by" code. 
        // we then subtract 1 from each category. this way we get categories with 0 to appear.
        categories.push(iataDisabilityCategories)
        categories = categories.flat()
        
        let categoryCounts = d3.nest().key(datum => datum)
            .rollup(data => {
                return {
                    y: data.length
                }
            })
            .entries(categories)
        
        const barGraphData = categoryCounts.map(category => {
            return {
                label: category.key,
                y: category.value.y - 1
            }
        })

        // format data for lounge daily usage graph
        let allDates = [0, 1, 2, 3, 4, 5, 6].map(idx => {
            return moment().tz("UTC").subtract(idx, "days").format("ll");
        });
        let dataDates = data.map(datum => {
            return datum.checkin.format("ll")
        })
        dataDates.push(allDates)
        dataDates = dataDates.flat()

        let dateCounts = d3.nest().key(datum => datum)
            .rollup(data => {
                return {
                    y: data.length
                }
            })
            .entries(dataDates)
            .sort((prev, next) => moment(prev.key).unix() - moment(next.key).unix())

        const lineGraphData = dateCounts.map((date, idx) => {
            return {
                label: date.key,
                x: idx,
                y: date.value.y - 1
            }
        })
        this.setState({barGraphData, lineGraphData})
    }

    _processHistoricalData = (data) => {
        data.map(datum => {
            datum.checkin = moment(datum.checkin.seconds * 1000).tz("UTC")
            if(datum.actualPickupTime)
                datum.actualPickupTime = moment(datum.actualPickupTime.seconds * 1000).tz("UTC")
            datum.pickupTime = moment(datum.pickupTime.seconds * 1000).tz("UTC")
            datum.checkinKey = datum.checkin.format("MMM D, YYYY")

            delete datum.name
            delete datum.notes
            delete datum.flightData
            return 1
        })

        let rawData = d3.nest()
            .key(datum => datum.checkinKey)
            .rollup(data => {
                return {
                    passengers: data.length || 0,
                    deliveries: d3.sum(data, datum => {return datum.purchased ? 1 : 0}),
                    dwell: d3.mean(data, datum => {return datum.actualPickupTime - datum.checkin } ),
                    ontime: 100.0 * d3.sum(data, datum => {
                        return datum.actualPickupTime && datum.actualPickupTime <= datum.pickupTime ? 1 : 0 
                    }) / (d3.sum(data, datum => { return datum.actualPickupTime ? 1 : 0}) || 1)
                }
            })
            .entries(data)

        let tableRows = rawData.map(datum => {
            return {
                date: datum.key,
                unix: moment(datum.key).unix(),
                passengers: datum.value.passengers,
                deliveries: datum.value.deliveries,
                ontime: datum.value.ontime,
                dwell: moment(datum.value.dwell).tz("UTC").format("HH:mm:ss")
            }
        }).sort((prev, next) => next.unix - prev.unix)

        this.setState({tableRows})
    }

    render = () => {
        if(this.state.redirectParams && this.state.redirectParams.pathname !== this.props.location.pathname)
            return <Redirect push to={this.state.redirectParams}/>

        return (
            <div style={{display: "flex"}}>
                <NavDrawer
                    listItems={drawerMenuItems}
                    currentPath={this.state.location.pathname}
                    onClickDrawerItemCallback={(redirectParams)=>this.setState({redirectParams})}
                    onClickLogoutCallback={this._onClickDrawerLogout}/>
                <main>
                    <div className={this.props.classes.spacerTop}/>
                    <Analytics 
                        state={this.state}
                        onClickDateRangeUpdate={this._onClickDateRangeUpdate}/>
                </main>
                <LoadingDialog show={this.state.loading}/>
                <StatusSnackbar
                    open={!isEmptyString(this.state.snackMessage.message)}
                    variant={this.state.snackMessage.level}
                    message={this.state.snackMessage.message}
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={this._onCloseStatusSnackbar}/>                  
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { airport } = state
    return { airport }
}

const mapActionCreatorsToProps = {
    clearCredentials
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionCreatorsToProps)
)(AnalyticsContainer)
