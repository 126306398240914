import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core"

import { PassengerDetails } from "../views/PassengerDetails"
import { StatusSnackbar, StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { AdminAirportAPI } from "../common/requests/admin/airport"
import { isEmptyString } from "../common/utils"

const moment = require("moment-timezone")

class PassengerDetailsDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.passengerData ? props.passengerData.name : "",
            categories: props.passengerData ? props.passengerData.categories : [],
            notes: props.passengerData ? props.passengerData.notes : "",
            flightCode: props.passengerData ? props.passengerData.flightCode : "",
            timezone: this.props.airport.timezone,
            scheduledPickupTime:  moment().tz(this.props.airport.timezone).add(30, "minutes"),
            gateCloseTime: moment().tz(this.props.airport.timezone).add(30, "minutes"),

            flightResults: [],
            flightData: null,

            // form error texts
            nameError: "",
            flightCodeError: "",

            snackMessage: {
                level: StatusSnackbarTypes.INFO,
                message: ""
            }
        }
    }

    componentWillMount = () => {
        if(this.props.variant === "update") {
            // in update mode, a passenger has flight data so we pull the latest info from
            // our  backend using the given flight code and scheduled departure
            
            if(this.props.passengerData.flightData) {
                this._getDepartureDetails(() => {
                    const scheduledDeparture = moment(this.props.passengerData.flightData.scheduled_departure_utc.seconds * 1000).tz("UTC")
                    if(this.state.flightResults.length > 1) {
                        const idx = this.state.flightResults.findIndex(flight => {
                            return flight.flight_number === this.state.flightCode &&
                                flight.scheduled_departure_utc.isSame(scheduledDeparture)
                        })
                        this._onSelectFlight(idx)
                    }
                })
            }
        }
    }

    _clearErrors = () => {
        this.setState({
            nameError: "",
            flightCodeError: ""
        })
    }

    /** event listeners  */
    _onChangeName = (name) => this.setState({name, nameError: ""})

    _onSelectCategories = (categories) => this.setState({categories})

    _onCloseSnackbar = () => {
        this.setState({
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            }
        })
    }    

    _onChangeNotes = (notes) => this.setState({notes})

    _onChangeFlightCode = (flightCode) => this.setState({flightCode: flightCode.toUpperCase(), flightCodeError: ""})

    _onClickSearch = () => {
        this._clearErrors()
        this.setState({flightResults: []})
        if(isEmptyString(this.state.flightCode)){
            this.setState({flightCodeError: "Required"})
        }
        else {
            this._getDepartureDetails()
        }
    }
    

    _onClickCheckinPassenger = () => {
        this._clearErrors()
        let nameError = "", flightCodeError = ""

        if(isEmptyString(this.state.name)){
            nameError = "Required"
        }
        if(isEmptyString(this.state.flightCode) || this.state.flightData === null){
            flightCodeError = "Missing flight information"
        }

        if(isEmptyString(nameError) && isEmptyString(flightCodeError)) {
            let passengerData = {
                name: this.state.name,
                checkin: moment().tz(this.props.airport.timezone),
                flightCode: this.state.flightCode,
                pickupTime: this.state.scheduledPickupTime,
                closeTime: this.state.gateCloseTime,
                categories: this.state.categories,
                status: "Checked-In",
                purchased: false,
                notes: this.state.notes,
                flightData: this.state.flightData
            }
            this.props.onClickCheckin(passengerData)
        }
        else {
            this.setState({nameError, flightCodeError})
        }
    }

    _onSelectFlight = (selectedFlightIdx) => {
        const flightData = this.state.flightResults[selectedFlightIdx]

        // also set the pickup and gate close times based on flight data
        let scheduledPickupTime = moment(flightData.estimatedDepartureLocal).subtract(30, "minutes")
        let gateCloseTime = moment(flightData.estimatedDepartureLocal).subtract(10, "minutes")

        this.setState({flightResults: [], flightData, scheduledPickupTime, gateCloseTime})
    }

    _onChangePickupTime = (scheduledPickupTime) => { 
        const localTZ = this.props.airport.timezone
        this.setState({scheduledPickupTime: scheduledPickupTime.tz(localTZ)})
    }
    _onChangeGateCloseTime = (gateCloseTime) => {
        const localTZ = this.props.airport.timezone
        this.setState({gateCloseTime: gateCloseTime.tz(localTZ)})
    }

    /** END event listeners */

    /** network requests */

    _getDepartureDetails = (onSuccess=()=>{}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(process.env.REACT_APP_API_HOST, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestDepartureInfo(this.props.airport.airportId, this.state.flightCode,
            (success) => {
                const data = success.data
                this._processFlightResults(data)
                // if there's only one flight, then automatically set it as the selected one.
                // otherwise set it to results and let the UI layer handle it
                if(data.length === 1) {
                    let scheduledPickupTime = moment(data[0].estimatedDepartureLocal).subtract(30, "minutes")
                    let gateCloseTime = moment(data[0].estimatedDepartureLocal).subtract(10, "minutes")
                    this.setState({loading: false, flightData: data[0], scheduledPickupTime, gateCloseTime}, onSuccess)
                }
                else {
                    this.setState({loading: false, flightData: null, flightResults: data}, onSuccess)
                }
                
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})   
            })
    }    

    /** END network requests */

    _processFlightResults = (flightData) => {
        // transform raw flight data into a format for display. getting the string times to be
        // moment objects in local time. in place update

        const localTZ = this.props.airport.timezone
        flightData.map(datum => {
            let estimatedDepartureUTC = moment(datum.estimated_departure_utc).tz("UTC")
            let scheduledDepartureUTC = moment(datum.scheduled_departure_utc).tz("UTC")
            let estimatedDepatureLocal = moment(estimatedDepartureUTC).tz(localTZ)
            let scheduledDepartureLocal = moment(scheduledDepartureUTC).tz(localTZ)
            datum.estimated_departure_utc = estimatedDepartureUTC
            datum.scheduled_departure_utc = scheduledDepartureUTC
            datum.estimatedDepartureLocal = estimatedDepatureLocal
            datum.scheduledDepartureLocal = scheduledDepartureLocal

            return 1
        })
    }


    render = () => {
        return (
            <Dialog open 
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>Passenger Details</DialogTitle>
                <DialogContent>
                    <PassengerDetails 
                        state={this.state}
                        variant={this.props.variant}
                        onChangeName={this._onChangeName}
                        onSelectCategories={this._onSelectCategories}
                        onChangeNotes={this._onChangeNotes}
                        onChangeFlightCode={this._onChangeFlightCode}
                        onClickSearch={this._onClickSearch}
                        onSelectFlight={this._onSelectFlight}
                        onChangePickupTime={this._onChangePickupTime}
                        onChangeGateCloseTime={this._onChangeGateCloseTime}
                        onClickCheckinPassenger={this._onClickCheckinPassenger}
                        onClose={this.props.onClose}/>
                    <StatusSnackbar
                        open={!isEmptyString(this.state.snackMessage.message)}
                        variant={this.state.snackMessage.level}
                        message={this.state.snackMessage.message}
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        onClose={this._onCloseSnackbar}/>
                </DialogContent>
            </Dialog>
        )
    }
}

PassengerDetailsDialog.propTypes = {
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    passengerData: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onClickCheckin: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    const { airport } = state
    return { airport }
}

const mapActionCreatorsToProps = {}

export default connect(mapStateToProps, mapActionCreatorsToProps)(PassengerDetailsDialog)
