import React from "react"
import PropTypes from "prop-types"

import {
    Grid,
    Typography,
    TextField,
    Button
} from "@material-ui/core"

import { isEmptyString } from "../common/utils"
import { FlightLookup } from "./FlightLookup"

const moment = require("moment-timezone")

export const ConfirmPickup = (props) => {
    const { classes, state } = props
    const currentTime = moment.tz(state.airport.timezone).format("LT")

    return (
        <div style={{display: "flex"}}>
            <main>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">Enter the name of the attendant picking up the passenger.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            label="Attendant Name"
                            value={state.attendantName}
                            variant="standard"
                            helperText={state.attendantNameError}
                            error={!isEmptyString(state.attendantNameError)}
                            onChange={event => { props.onChangeAttendantName(event.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">Passenger Notes</Typography>
                        <Typography variant="caption">Share any notes and flight info with attendant.</Typography>
                        <div style={{height: 20}}/>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Notes"
                            value={state.passengerNotes}
                            multiline
                            rowsMax="6"
                            rows="6"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">Passenger Flight Info</Typography>
                        <FlightLookup state={state} hideSearch/>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="text"
                            onClick={props.onClose}>Close</Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            color="primary"
                            variant="contained"
                            onClick={props.onClickConfirmPickup}>{`Confirm Pick-Up - ${currentTime}`}</Button>
                    </Grid>                    
                </Grid>
            </main>
        </div>
    )
}

ConfirmPickup.propTypes = {
    state: PropTypes.object.isRequired,
    onChangeAttendantName: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClickConfirmPickup: PropTypes.func.isRequired
}

ConfirmPickup.defaultProps = {
    onChangeAttendantName: (value) => {},
    onClose: () => {},
    onClickConfirmPickup: () => {},
}