import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core"

import { StatusSnackbar, StatusSnackbarTypes } from "../common/components/StatusSnackbar"
import { AdminAirportAPI } from "../common/requests/admin/airport"
import { isEmptyString } from "../common/utils"
import { ConfirmPickup } from "../views/ComfirmPickup"


const moment = require("moment-timezone")

class ConfirmPickupDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flightCode: "",
            flightData: null,
            passengerNotes: props.notes,
            attendantName: "",
            attendantNameError: "",
            
            airport: props.airport,
            loading: false,
            snackMessage: {
                message: "",
                level: StatusSnackbarTypes.INFO
            }
        }
    }

    /* component lifecycle methods */

    componentWillMount = () => {    
        this._getDepartureDetails((flights) => {
            const scheduledDeparture = moment(this.props.flightData.scheduled_departure_utc.seconds * 1000).tz("UTC")
            let flightData
            if(flights.length > 1) {
                const idx = flights.findIndex(flight => {
                    return flight.flight_number === this.props.flightCode &&
                        flight.scheduled_departure_utc.isSame(scheduledDeparture)
                })
                flightData = flights[idx]
            }
            else {
                flightData = flights[0]
            }
            this.setState({flightData})
        })
    }

    /** END component lifecycle */

    /** event listeners */

    _onChangeAttendantName = (attendantName) => this.setState({attendantName, attendantNameError: ""})

    _onClickConfirmPickup = () => {
        this._clearErrors()
        if(isEmptyString(this.state.attendantName)){
            this.setState({attendantNameError: "Required"})
        }
        else {
            const currentTime = moment.tz(this.props.airport.timezone)
            let payload = {
                status: `Picked Up ${currentTime.format("LT")}`,
                pickupAttendant: this.state.attendantName,
                actualPickupTime: currentTime
            }
            this.props.onClickPickup(payload)
        }
    }

    /** END event listeners */

    /** network requests */

    _getDepartureDetails = (onSuccess=(flights)=>{}) => {
        this.setState({loading: true})
        const api = new AdminAirportAPI(process.env.REACT_APP_API_HOST, process.env.REACT_APP_ADMIN_USER,
            process.env.REACT_APP_ADMIN_SECRET)
        api.requestDepartureInfo(this.props.airport.airportId, this.props.flightCode,
            (success) => {
                const data = success.data
                this._processFlightResults(data)
                this.setState({loading: false}, () => onSuccess(data))
            },
            (error) => {
                const message = (error.response === undefined) ? error.message : error.response.data.error
                const snackMessage = {
                    level: StatusSnackbarTypes.ERROR,
                    message
                }
                this.setState({loading: false, snackMessage})   
            })
    }    

    /** END network requests */

    _clearErrors = () => {
        this.setState({
            attendantNameError: ""
        })
    }

    _processFlightResults = (flightData) => {
        // transform raw flight data into a format for display. getting the string times to be
        // moment objects in local time. in place update

        const localTZ = this.props.airport.timezone
        flightData.map(datum => {
            let estimatedDepartureUTC = moment(datum.estimated_departure_utc).tz("UTC")
            let scheduledDepartureUTC = moment(datum.scheduled_departure_utc).tz("UTC")
            let estimatedDepatureLocal = moment(estimatedDepartureUTC).tz(localTZ)
            let scheduledDepartureLocal = moment(scheduledDepartureUTC).tz(localTZ)
            datum.estimated_departure_utc = estimatedDepartureUTC
            datum.scheduled_departure_utc = scheduledDepartureUTC
            datum.estimatedDepartureLocal = estimatedDepatureLocal
            datum.scheduledDepartureLocal = scheduledDepartureLocal

            return 1
        })
    }    

    render = () => {
        return (
            <Dialog open
                disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle>Confirm Pickup</DialogTitle>
                <DialogContent>
                    <ConfirmPickup
                        state={this.state}
                        onChangeAttendantName={this._onChangeAttendantName}
                        onClickConfirmPickup={this._onClickConfirmPickup}
                        onClose={this.props.onClose}/>
                </DialogContent>
            </Dialog>
        )
    }
}

ConfirmPickupDialog.propTypes = {
    flightCode: PropTypes.string.isRequired,
    flightData: PropTypes.object.isRequired,
    notes: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onClickPickup: PropTypes.func.isRequired
}

ConfirmPickupDialog.defaultProps = {
    notes: "",
    onClose: () => {},
    onClickPickup: (payload) => {}
}

function mapStateToProps(state) {
    const { airport } = state
    return { airport }
}

const mapActionCreatorsToProps = {}

export default connect(mapStateToProps, mapActionCreatorsToProps)(ConfirmPickupDialog)
