import React from "react"
import PropTypes from "prop-types"
import MomentUtils from '@date-io/moment'

import {
    Grid,
    Button,
    TextField,
    Typography,
    Divider,
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from "@material-ui/core"

import { 
    MuiPickersUtilsProvider,
    DateTimePicker 
}  from "@material-ui/pickers"


import { FlightLookup } from "./FlightLookup"
import { isEmptyString } from "../common/utils"
import { iataDisabilityCategories } from "../../constants"

const moment = require("moment-timezone")

function _renderButtons(props) {
    const { state } = props
    const currentTime = moment().tz(state.timezone).format("LT")

    let buttonText = "Update"
    if(props.variant === "create") {
        buttonText = `Complete Check-in - ${currentTime}`
    }

    return (
        <Grid container  justify="flex-end">
            <Grid item>
                <Button
                    variant="text"
                    onClick={props.onClose}>Close</Button>
            </Grid>            
            <Grid item>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={props.onClickCheckinPassenger}>{buttonText}</Button>
            </Grid>
        </Grid>        
    )
}

export const PassengerDetails = (props) => {
    const { state } = props

    return (
        <div style={{display: "flex"}}>
            <main>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">Basic Info</Typography>
                    </Grid>                    
                    <Grid item xs={6}>
                        <TextField 
                            fullWidth
                            variant="standard" 
                            label="Name"
                            value={state.name}
                            helperText={state.nameError}
                            error={!isEmptyString(state.nameError)}
                            onChange={event => props.onChangeName(event.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel shrink>Categories</InputLabel>
                            <Select
                                multiple
                                value={state.categories}
                                renderValue={ () => state.categories.join(", ")}
                                onChange={(event) => props.onSelectCategories(event.target.value)}
                                input={<Input/>}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200
                                        }
                                    }
                                }}>
                                {
                                    iataDisabilityCategories.map((category, idx) => (
                                        <MenuItem key={category} value={category}>
                                            <Checkbox checked={state.categories.indexOf(category) > -1} color="primary"/>
                                            <ListItemText primary={category}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>   
                    <Grid item xs={12}>
                        <TextField 
                            fullWidth
                            multiline
                            variant="outlined" 
                            label="Additional Notes" 
                            value={state.notes}
                            onChange={event => props.onChangeNotes(event.target.value)}
                            rowsMax="6"
                            rows="6"/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{height: 20}}/>                         
                        <Typography variant="body1">Add Flight Info</Typography>
                    </Grid>
                </Grid>
                <FlightLookup 
                    state={state}
                    onClickSearch={props.onClickSearch}
                    onSelectFlight={props.onSelectFlight}
                    onChangeFlightCode={props.onChangeFlightCode}/> 
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container>
                        <Grid item xs={6}>
                            <DateTimePicker
                                fullWidth
                                color="primary"
                                value={state.scheduledPickupTime}
                                disablePast
                                onChange={(moment) => props.onChangePickupTime(moment)}
                                label="Scheduled Pick-up Time"
                                showTodayButton/>                     
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimePicker
                                fullWidth
                                color="primary"
                                value={state.gateCloseTime}
                                disablePast
                                onChange={(moment) => props.onChangeGateCloseTime(moment)}
                                label="Gate Close Time"
                                showTodayButton/>    
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>                                  
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{height: 10}}/>
                        <Divider />
                        <div style={{height: 10}}/>
                    </Grid>
                </Grid>
                { _renderButtons(props) }
            </main>
        </div>
    )
}

PassengerDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(["create", "update"]).isRequired,
    onClickCheckinPassenger: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onSelectCategories: PropTypes.func.isRequired,
    onChangeNotes: PropTypes.func.isRequired,
    onClickSearch: PropTypes.func.isRequired,
    onSelectFlight: PropTypes.func.isRequired,
    onChangeFlightCode: PropTypes.func.isRequired,
    onChangePickupTime: PropTypes.func.isRequired,
    onChangeGateCloseTime: PropTypes.func.isRequired,
    
}

PassengerDetails.defaultProps = {
    onClickCheckinPassenger: () => {},
    onChangeName: (value) => {},
    onSelectCategories: (categories) => {},
    onChangeNotes: (value) => {},
    onClickSearch: () => {},
    onSelectFlight: (idx) => {},
    onChangeFlightCode: (value) => {},
    onChangePickupTime: (moment) => {},
    onChangeGateCloseTime: (moment) => {},
}