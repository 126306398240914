import React from "react"
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid"

import CanvasJSReact from "../common/canvasjs.react"
import { FetchyColors } from "../common/fetchyfoxtheme"
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


export const LineGraph = (props) => {
    const { state } = props

    const graphOptions = {
        animationEnabled: true,
        exportEnabled: true,
        zoomEnabled: true,
        theme: "light2",
        title: { text: props.title },
        axisX: { title: props.xLabel },
        axisY: [
            {
                lineColor: FetchyColors.primary,
                titleFontColor: FetchyColors.text,
                labelFontColor: FetchyColors.text
            }
        ],
        data: [
            {
                type: "spline",
                name: props.dataName,
                showInLegend: true,
                dataPoints: props.graphData,
                color: FetchyColors.primary
            },
        ]
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                <CanvasJSChart options={graphOptions} style={{width: "100%"}}/>
            </Grid>
        </Grid>
    )
}

LineGraph.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    graphData: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    xLabel: PropTypes.string.isRequired,
    dataName: PropTypes.string,
}

LineGraph.defaultProps = {
    graphData: [
        {label: 10, x: 1, y: Math.random() * 2000},
        {label: 20, x: 2, y: Math.random() * 2000},
        {label: 30, x: 3, y: Math.random() * 2000},
        {label: 40, x: 4, y: Math.random() * 2000},
        {label: 50, x: 5, y: Math.random() * 2000},
        {label: 60, x: 6, y: Math.random() * 2000},
        {label: 70, x: 7, y: Math.random() * 2000},
    ],
    dataName: "data",
}

export default LineGraph