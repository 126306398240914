import { PaxCheckinCollection } from "./core"

export class PaxCheckinAPI {

    constructor(firebaseApp) {
        // firebaseApp is an object returned by firebase.initializeApp
        this.db = firebaseApp.firestore()
    }


    getPassengers = (startDate, endDate, onSuccess=(payload)=>{}, onError=(message)=>{}) => {
        // startDate and endDate are both moment.js objects

        this.db.collection(PaxCheckinCollection)
            .where("checkin", ">", startDate.tz("UTC").startOf("day").toDate())
            .where("checkin", "<=", endDate.tz("UTC").endOf("day").toDate() )
            .get().then(snapshot => {
                let passengerData = []
                snapshot.forEach(doc => {
                    passengerData.push({...{id: doc.id}, ...doc.data()})
                })
                onSuccess(passengerData)
            })
            .catch(error => {
                onError(error.message)
            })
    }

    updatePassenger = (documentId, data, onSuccess=()=>{}, onError=()=>{}) => {
        // https://firebase.google.com/docs/firestore/manage-data/add-data#update-data
        // data is an object, all time values must be Date objects, not moments
        this.db.collection(PaxCheckinCollection).doc(documentId).update(data)
            .then(() => {
                onSuccess()
            }).catch(error => {
                onError()
            })  
    }

    addPassenger = (name, disabilityCategories, notes, flightCode, flightData, checkinTime,
        scheduledPickupTime, gateCloseTime, payload={}, onSuccess=()=>{}, onError=(message)=>{}) => {
        // all the time values are Date objects
        // disabilityCategories is a list
        let data = {
            ...payload,
            name,
            categories: disabilityCategories,
            notes,
            flightCode,
            flightData,
            checkin: checkinTime,
            pickupTime: scheduledPickupTime,
            closeTime: gateCloseTime,
        }
        this.db.collection(PaxCheckinCollection).doc().set(data)
            .then(() => {
                onSuccess()
            })
            .catch((error) => {
                onError(error.message)
            })             
    }

    registerListener = (startDate, endDate, callback=(passengerData)=>{}, onSuccess=(listener)=>{}) => {
        // startDate and endDate are moment objects
        const listener = this.db.collection(PaxCheckinCollection)
            .where("checkin", ">", startDate.tz("UTC").startOf("day").toDate())
            .where("checkin", "<=", endDate.tz("UTC").endOf("day").toDate() )        
            .onSnapshot(snapshot => {
                let passengers = []
                snapshot.forEach(doc => {
                    passengers.push({...{id: doc.id}, ...doc.data()})
                })
                callback(passengers)
            });
        onSuccess(listener)
    }
}